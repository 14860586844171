export default defineNuxtPlugin((nuxtApp) => {
  if (import.meta.server) return

  const gtmId = import.meta.env.VITE_GOOGLE_TAG_MANAGER_ID
  if (!gtmId) {
    console.warn('GTM ID не указан в .env')
    return
  }

  window.dataLayer = window.dataLayer || []

  nuxtApp.hook('page:finish', () => {
    window.dataLayer.push({
      event: 'page_view',
      page_path: window.location.pathname,
      page_title: document.title,
    })
  })

  return {
    provide: {
      trackEvent: (data: Record<string, any> = {}) => {
        console.log('trackEvent', data)
        window.dataLayer.push({ ...data })
      },
    },
  }
})
